<template>
  <el-dialog
    title="复制角色"
    width="450px"
    :visible="show"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form label-width="100px" :model="detail" :rules="rules" ref="form">
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          placeholder="请输入"
          v-model="detail.roleName"
          clearable
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="角色编码" prop="roleCode">
        <el-input
          placeholder="请输入"
          v-model="detail.roleCode"
          clearable
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="复制角色数量" prop="coptNum">
        <el-input
          placeholder="请输入"
          v-model="form.copyNum"
          type="number"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="复制已分配权限">
        <el-switch v-model="form.copyAuth"> </el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/auth/role2";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      form: { copyNum: 1, copyAuth: false },
      rules: {
        roleCode: [
          { required: true, message: "请输入角色编码", trigger: "blur" },
        ],
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        copyNum: [
          { required: true, message: "请输入角色数量", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.form = { copyNum: 1, copyAuth: false };
      } else {
        this.$refs["form"].resetFields();
      }
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    add() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        const params = {
          ...this.form,
          roleId: this.detail.id,
        };
        api.copy(this.rangeFlag, params).then(() => {
          this.$msg.success("操作成功");
          this.$emit("close");
        });
      });
    },
  },
};
</script>
