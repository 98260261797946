<template>
  <div>
    <el-dialog
      title="分配权限"
      width="500px"
      :visible="show"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-tree
        :data="roleAuthData"
        ref="tree"
        show-checkbox
        :default-expanded-keys="expandedKeys"
        node-key="id"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <!-- <el-tag type="success">{{ data.name }}</el-tag> -->
          {{ data.name }}
          <el-tag size="mini">{{ data.code }}</el-tag>
        </span>
      </el-tree>

      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/auth/role2";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      roleAuthData: [],
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      expandedKeys: [],
    };
  },
  watch: {
    show(val) {
      if (val && this.detail && this.detail.id) {
        this.getRoleAuth();
      }
    },
  },
  created() {},
  methods: {
    getRoleAuth() {
      api
        .getRoleAuth(this.rangeFlag, { roleId: this.detail.id })
        .then((res) => {
          this.roleAuthData = res.data.baseDataTree;
          this.expandedKeys = res.data.checkedDataIds;
          this.$refs.tree.setCheckedKeys(res.data.checkedDataIds);
        });
    },

    save() {
      const params = {
        roleId: this.detail.id,
        checkedDataIds: this.$refs.tree.getCheckedKeys(),
      };
      api.roleAuth(this.rangeFlag, params).then(() => {
        this.$msg.success("操作成功");
        this.close();
      });
    },
    close() {
      this.$emit("update:show", false);
    },
  },
};
</script>
