<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="名称">
            <el-input
              placeholder="请输入"
              v-model="form.roleName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="编码">
            <el-input
              placeholder="请输入"
              v-model="form.roleCode"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="form.useStatus" clearable>
              <el-option label="已启用" :value="true"></el-option>
              <el-option label="已禁用" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="primary" plain @click="handleSave(true)"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table
          ref="table"
          :data="tableData"
          v-loading="tableLoading"
          border
          stripe
          height="100%"
        >
          <el-table-column
            prop="roleName"
            label="角色名称"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="roleCode"
            label="角色编码"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="countNum"
            label="关联用户数量"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="useStatus"
            label="状态"
            align="center"
            min-width="100"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.useStatus ? 'enable-status' : 'disable-status'
                "
                >{{ scope.row.useStatus ? "已启用" : "已禁用" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="createUser"
            label="创建人"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="updateUser"
            label="更新人"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center"
            min-width="150"
          />
          <el-table-column
            label="操作"
            align="center"
            min-width="180"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleSave(false, scope.row)">
                编辑
              </el-button>
              <!-- <el-button type="text" @click="handleEditStatus(scope.row)"> {{ scope.row.useStatus ? '禁用' : '启用' }} </el-button> -->
              <el-button type="text" @click="handleRoleAuth(scope.row)">
                分配权限
              </el-button>
              <el-button
                v-if="rangeFlag == 'app'"
                type="text"
                @click="handleThirdPartyAuth(scope.row)"
              >
                外接权限
              </el-button>
              <el-button type="text" @click="handleCopy(scope.row)">
                复制
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination
          class="mt10"
          :page-size="page.size"
          :current-page="page.current"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </template>
    </common-layout>

    <role-modal
      :rangeFlag="rangeFlag"
      :show.sync="roleDialog"
      :detail="curRow"
      @close="closeModal"
    />
    <role-auth-modal
      :rangeFlag="rangeFlag"
      :show.sync="roleAuthDialog"
      :detail="curRow"
      @close="closeModal"
    />
    <third-party-auth-modal
      :rangeFlag="rangeFlag"
      :show.sync="roleThirdPartyDialog"
      :detail="curRow"
      @close="closeModal"
    />
    <copy-modal
      :rangeFlag="rangeFlag"
      :show.sync="showCopy"
      :detail="curRow"
      @close="closeModal"
    />
  </div>
</template>
<script>
import copyModal from "./copyModal";
import api from "@/api/auth/role2";
import RoleModal from "./RoleModal.vue";
import RoleAuthModal from "./RoleAuthModal.vue";
import ThirdPartyAuthModal from "./ThirdPartyAuthModal.vue";
export default {
  props: {
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  components: { copyModal, RoleModal, RoleAuthModal, ThirdPartyAuthModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      roleDialog: false,
      roleAuthDialog: false,
      roleThirdPartyDialog: false,
      showCopy: false,
      curRow: {},
    };
  },
  // computed: {
  //   rangeFlagC: {
  //     set: (rangeFlag) => {
  //       debugger;
  //       this.rangeFlag = rangeFlag;
  //     },
  //   },
  // },
  mounted() {
    this.query();
  },
  watch: {
    rangeFlag: function () {
      this.query();
    },
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      };
      this.tableLoading = true;
      api
        .query(this.rangeFlag, params)
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleQuery() {
      this.page.current = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.query();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.query();
    },
    handleSave(isAdd, row) {
      this.curRow = isAdd ? { useStatus: true } : _.cloneDeep(row);
      this.roleDialog = true;
    },
    handleRoleAuth(row) {
      this.curRow = _.cloneDeep(row);
      this.roleAuthDialog = true;
    },
    handleThirdPartyAuth(row) {
      this.curRow = _.cloneDeep(row);
      this.roleThirdPartyDialog = true;
    },
    handleCopy(row) {
      this.curRow = _.cloneDeep(row);
      this.showCopy = true;
    },
    closeModal() {
      this.roleDialog = false;
      this.showRes = false;
      this.showCopy = false;
      this.query();
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.delete(this.rangeFlag, { id: row.id }).then(() => {
          this.$msg.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>
