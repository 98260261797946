<template>
  <el-dialog
    title="外接系统权限关联"
    width="500px"
    :visible="show"
    @close="close"
  >
    <el-form label-width="100px" :model="editData" :rules="rules" ref="form">
      <el-form-item label="外部系统" prop="type">
        <el-select v-model="editData.type" placeholder="请选择">
          <el-option
            v-for="data in originalData"
            :key="data.type"
            :label="data.type"
            :value="data.type"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联权限" prop="thirdPartyAuths">
        <el-checkbox-group
          :max="editData.isCheckbox ? undefined : 1"
          v-model="editData.authKeys"
        >
          <el-checkbox
            v-for="auth in editData.allAuthKeys"
            :key="auth.key"
            :label="auth.key"
          />
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/auth/role2";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      originalData: [],
      editData: {},
      rules: {
        type: [
          { required: true, message: "请选择外接软件类型", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.getRoleThirdParty();
      }
    },
  },
  created() {},
  methods: {
    getRoleThirdParty() {
      api
        .getRoleThirdParty(this.rangeFlag, { roleId: this.detail.id })
        .then((res) => {
          this.originalData = res.data;
          this.editData = this.originalData[0];
          if (!this.editData.isCheckbox) {
            this.radio =
              this.editData.authKeys == null ? null : this.editData.authKeys[0];
          }
        });
    },

    close() {
      this.$emit("update:show", false);
    },
    save() {
      api.updateRoleThirdParty(this.rangeFlag, this.editData).then(() => {
        this.$msg.success("操作成功");
        this.close();
      });
    },
  },
};
</script>

<style scoped>
.role-layout {
  flex: 1 250px;
}
</style>
