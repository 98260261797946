import http from "@/utils/axios";
import common from "@/common";
const api = {
  query: (rangeFlag, data) =>
    http.get(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/query`,
      data
    ),
  add: (rangeFlag, data) =>
    http.post(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/add`,
      data
    ),
  modify: (rangeFlag, data) =>
    http.put(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/modify`,
      data
    ),
  delete: (rangeFlag, data) =>
    http.delete(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/delete`,
      data
    ),
  copy: (rangeFlag, data) =>
    http.post(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/copy`,
      data
    ),
  // detail: (data) => http.get(`/tenant-user/roleInfo/${common.getApiCata()}/detail`, data),
  getRoleAuth: (rangeFlag, data) =>
    http.get(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/roleAuth`,
      data
    ),
  roleAuth: (rangeFlag, data) =>
    http.put(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/roleAuth`,
      data
    ),

  ///////////第三方权限关联///////////////////////////////////////////
  getRoleThirdParty: (rangeFlag, data) =>
    http.get(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/roleThirdParty`,
      data
    ),
  updateRoleThirdParty: (rangeFlag, data) =>
    http.put(
      `/tenant-user/roleInfo/${common.getApiCata()}/${rangeFlag}/roleThirdParty`,
      data
    ),
};
export default api;
